<template>
  <div v-if="examQuestionRes && examQuestionRes.exam" class="ma-3">
    <v-row class="">
      <v-col cols="12" md="12">
        <v-card class="d-flex align-center rounded-lg pa-2 px-4" flat>
          <h4 class="">
            {{
              examQuestionRes.exam
                ? `${examQuestionRes.exam.title} / ${examQuestionRes.exam.subjectName}`
                : ""
            }}
          </h4>

          <v-spacer></v-spacer>
          <v-btn tile color="primary" @click="back()" class="rounded-xl">
            {{ $t("back") }}
            <v-icon right> mdi-arrow-left </v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" md="9" sm="12">
        <v-card class="align-center rounded-lg pa-2 px-4" flat>
          <div>
            {{
              `${examQuestionRes.exam.departmentName} / ${examQuestionRes.exam.stageName} / ${examQuestionRes.exam.studyName} / ${examQuestionRes.exam.sectionName}`
            }}
          </div>

          <v-expand-transition>
            <Question
              :key="componentsKeys[0]"
              :examQuestions="examQuestionRes.exam.examQuestions"
              :examGuid="examQuestionRes.exam.guid"
              :isActive="examQuestionRes.exam.isActive"
              :subjectGuid="examQuestionRes.exam.subjectGuid"
              v-on:send-result-values="getQuestions(0)"
              v-if="selectedItem == 0"
            ></Question>

            <ExamSettings
              :key="componentsKeys[1]"
              :examQuestions="examQuestionRes"
              :examGuid="examQuestionRes.exam.guid"
              :selectedItem="selectedItem"
              v-on:send-result-values="getQuestions(1)"
              v-else-if="selectedItem == 1"
            ></ExamSettings>
            <AddStudentToExam
              :key="componentsKeys[2]"
              :examQuestions="examQuestionRes"
              :examGuid="examQuestionRes.exam.guid"
              :selectedItem="selectedItem"
              v-on:send-result-values="getQuestions(2)"
              v-else-if="selectedItem == 2"
            ></AddStudentToExam>
            <StudentsResult
              :key="componentsKeys[3]"
              :isActive="examQuestionRes.exam.isActive"
              :examGuid="examQuestionRes.exam.guid"
              :exam="examQuestionRes.exam"
              v-else-if="selectedItem == 3"
            ></StudentsResult>
            <Statistics
              :examGuid="examQuestionRes.exam.guid"
              v-else-if="selectedItem == 4"
            >
            </Statistics>
            <p v-else></p>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <v-card class="align-center rounded-lg" flat>
          <v-list rounded>
            <!-- <v-subheader>REPORTS</v-subheader> -->
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item class="" v-for="(item, i) in items" :key="i">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
        <v-card flat class="align-center rounded-lg mt-3">
          <v-card-text dir="auto" style="white-space: pre-line !important">
            <v-data-table
              :headers="headers"
              :items="questionsCount"
              item-key="questionType"
              hide-default-header
              hide-default-footer
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Question from "../../components/exams/question.vue";
import ExamSettings from "../../components/exams/examSettings.vue";
import AddStudentToExam from "../../components/exams/addStudentToExam.vue";
import StudentsResult from "../../components/exams/studentsResult.vue";
import Statistics from "../../components/exams/examStatistics.vue";

export default {
  components: {
    Question,
    ExamSettings,
    AddStudentToExam,
    StudentsResult,
    Statistics,
  },
  props: ["id"],
  data() {
    return {
      headers: [
        { text: "questions", value: "questionType" },
        { text: "count", value: "count" },
      ],
      questionsCount: [],
      componentsKeys: [
        Math.random(),
        Math.random(),
        Math.random(),
        Math.random(),
      ],

      randomQuestion: null,
      examQuestionRes: null,
      overlay: false,
      selectedItem: 0,
      items: [
        {
          text: this.$t("exam.ExamQuestions"),
          icon: "mdi-frequently-asked-questions",
        },
        { text: this.$t("exam.ExamSettings"), icon: "mdi-tune" },
        { text: this.$t("exam.AddStudents"), icon: "mdi-account-plus" },
        { text: this.$t("exam.examResults"), icon: "mdi-file-check" },
        { text: this.$t("exam.examStatistics"), icon: "mdi-chart-bar" },
      ],
    };
  },
  methods: {
    getQuestions(keyval) {
      this.overlay = true;

      this.axios
        .get(`Exams/GetQuestions?ExamGuid=${this.id}&actionName=showExam`)
        .then((res) => {
          // console.log(res);
          this.examQuestionRes = res.data.data;
          this.getQuestionsCount();
          // console.log(this.examQuestionRes);
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.overlay = false;
          if (keyval) {
            this.componentsKeys[keyval] = Math.random();
            this.$forceUpdate();
          }
        });
    },

    getQuestionsCount() {
      if (
        this.examQuestionRes.exam &&
        this.examQuestionRes.exam.examQuestions
      ) {
        this.questionsCount = [
          {
            questionType: this.$t("exam.FillInTheBlank"),
            count: this.examQuestionRes.exam.examQuestions.filter(
              (m) => m.questionType == "FillInTheBlanks"
            ).length,
          },
          {
            questionType: this.$t("exam.FillInTheBlankNumericValue"),
            count: this.examQuestionRes.exam.examQuestions.filter(
              (m) => m.questionType == "Number"
            ).length,
          },
          {
            questionType: this.$t("exam.MultipleChoices"),
            count: this.examQuestionRes.exam.examQuestions.filter(
              (m) => m.questionType == "MultiChoice"
            ).length,
          },
          {
            questionType: this.$t("exam.ChooseOne"),
            count: this.examQuestionRes.exam.examQuestions.filter(
              (m) => m.questionType == "OneChoice"
            ).length,
          },
          {
            questionType: this.$t("exam.UploadAFile"),
            count: this.examQuestionRes.exam.examQuestions.filter(
              (m) => m.questionType == "AttachedFile"
            ).length,
          },
          {
            questionType: this.$t("exam.TheTotalNumberOfQuestions"),
            count: this.examQuestionRes.exam.examQuestions.length,
          },
        ];
      } else this.questionsCount = [];
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getQuestions();
  },
  beforeCreate() {},
  mounted() {},
};
</script>

<style></style>
